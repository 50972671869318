import { Flex, Spin } from "antd"

const Spinner = () => {
    return (
        <div className="spinner" style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
            top: 0,
            backgroundColor: '#F4F7FA',
        }}>
            <Flex align="center" gap="middle" >
                <Spin size="large" />
            </Flex>
        </div>
    )
}

export { Spinner }