import axios from "axios";


// export const API_URL = "http://65.108.90.115:7770/iiko-integration/api/v1";
export const getApiUrl = (type: String) => {
    let integration = '';
    switch (type) {
        case 'ik1':
            integration = 'iiko-integration';
            break;
        case 'ik2':
            integration = 'iiko-integration2';
            break;
        case 'rk':
            integration = 'rkeeper-integration';
            break;
        default:
            integration = '';
            break;
    }
    return `https://pay.qrix.az/${integration}/api/v1`;
};



export const getTableOrder = async (type: String, organization_id: any, table_id: any) => {
    const http = axios.create({
        baseURL: getApiUrl(type),
        headers: {
            Accept: "application/json",
        },
        withCredentials: false,
    });

    http.interceptors.response.use(
        function (response) {
            console.log(response);
            return response;
        },
        function (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                return { "authorized": false };
            }
            return { "authorized": false };
        }
    );

    console.log('getApiUrl(type)', getApiUrl(type));

    const { data } = await http.get<any>(`${getApiUrl(type)}/get_active_table_order?organization_id=${organization_id}&table_id=${table_id}`);
    return data; //result содержит необходимые данные
};

export const makePayment = async (type: String, request: any) => {
    const { data } = await axios.post<any>(`${getApiUrl(type)}/make_payment`, request);
    return data;
};