import logo from "../../../../assets/images/logo.svg";
import React from "react";
import './LogoComponent.css'


const LogoComponent = () => {
    return (
        <div className="App-header">
            <h1 className='headText'>Sizin sifarişiniz</h1>
            <img className="logoClass" src={logo} />
        </div>)
}


export default LogoComponent