import React from 'react'
import LogoComponent from '../Receipt/components/LogoComponent/LogoComponent'
import PaymentForm from '..//Receipt/components/PaymentForm/PaymentForm'

const Receipt = () => {
    return (
        <div className="receipt">
            <LogoComponent />
            <PaymentForm />
        </div>
    )
}

export default Receipt