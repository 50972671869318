import React, { useEffect } from 'react';
import './assets/styles/App.scss';
import './assets/TTHoves/fonts.css'
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import Receipt from './pages/Receipt/Receipt';


const router = createBrowserRouter([
  {
    path: '/:type/:restoranId/:tableId',
    element: <HomePage />,
  },
  {
    path: '/receipt',
    element: <Receipt />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
