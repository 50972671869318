import React, { useState, useEffect } from 'react';
import { Link, useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import './HomePage.scss';

import walletIcon from '../../assets/images/wallet.svg'
import menuIcon from '../../assets/images/menu.svg'
import { IoIosArrowForward } from "react-icons/io";
import { getTableOrder } from '../../servises';
import { Spinner } from '../../components/Spinner';

interface OrderItem {
    type: string;
    productName: string;
    productCount: number;
    productPrice: number;
    resultSum: number;
    status: string;
}

interface TableOrderResponse {
    success: boolean;
    message: string;
    result: {
        organizationId: string;
        tableId: string[];
        orderId: string;
        restaurantName: string;
        waiterName: string;
        totalAmount: number;
        serviceFee: number | null;
        organizationName: string
        orderItems: OrderItem[];
        tipAmount: number;
        iikoOrganizationId: string;
        iikoTableId: string;
        iikoOrderId: string;
        waiterId: string;
        waiterPhoneNumber: string;
    };
    menuUrl: string;
    restaurantName: string;
}

const HomePage = () => {
    const { type, restoranId, tableId } = useParams();
    const [loading, setLoading] = useState(false);
    const [menuUrl, setMenuUrl] = useState('');
    const [data, setData] = useState<TableOrderResponse | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(restoranId, tableId);
    }, []);

    const fetchData = async (organization_id: any, table_id: any) => {
        try {
            setLoading(true);
            const response: TableOrderResponse = await getTableOrder(type || '', organization_id, table_id);
            setData(response);
            setMenuUrl(response.menuUrl);
            console.log('restaurantName in home', response.restaurantName);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const onWalletClick = () => {
        navigate('/receipt', { state: { type: type, restoranId: restoranId, tableId: tableId, data: data } });
    }



    return (
        <>
            {loading ? <Spinner /> : <div className="homepage">
                <h1 className="homepage-title">{data?.restaurantName}</h1>
                <i className="homepage-subtitle">Nuş olsun !</i>
                <div className="homepage-buttons">
                    <div className="homepage-button" onClick={onWalletClick}>
                        <img src={walletIcon} alt="" className="button-icon" />
                        <p className="button-text">Hesab ödənişi</p>
                        <IoIosArrowForward className='arrow' color='#9BA5B5' />
                    </div>
                    <div className="homepage-button" onClick={() => {
                        // console.log('menuUrl', menuUrl);
                        if (menuUrl !== null && menuUrl !== '' && menuUrl !== undefined) { window.location.href = menuUrl; }
                    }}>
                        <img src={menuIcon} alt="" className="button-icon" />
                        <p className="button-text">Menyu {(menuUrl !== null && menuUrl !== '' && menuUrl !== undefined) ? "" : <span>(tezliklə)</span>}</p>
                        <IoIosArrowForward className='arrow' color='#9BA5B5' />
                    </div>
                </div>
            </div>}

        </>
    )
}

export default HomePage